@font-face{
    font-family:poppins-light;
    src:url(../../public/font/Poppins-light.ttf);
}
@font-face{
    font-family:poppins-medium;
    src:url(../../public/font/poppins-medium.ttf);
}
@font-face{
    font-family:poppins-regular;
    src:url(../../public/font/Poppins-regular.ttf);
}
@font-face{
    font-family:poppins-semibold;
    src:url(../../public/font/poppins-semibold.ttf);
}
@font-face{
    font-family:poppins-bold;
    src:url(../../public/font/poppins-bold.ttf);
}