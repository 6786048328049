.dws_menu * {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #3f3f3f;
}

.dws_menu ul,
.dws_menu ol {
  list-style: none;
}

.dws_menu > ul {
  display: flex;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
  @media screen and (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.dws_menu > ul li {
  position: relative;
}

.dws_menu > ul li > a i.fa {
  position: absolute;
  top: 15px;
  left: 12px;
  font-size: 18px;
}

.dws_menu > ul li a {
  display: block;
  padding: 15px 30px 15px 40px;
  font-size: 16px;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.dws_menu li a:hover {
  background-color: #333333;
  color: #ffffff;
  transition: all 0.3s ease;
}

/*sub menu*/
.dws_menu li ul {
  position: absolute;
  min-width: 150px;
  display: none;
  background-color: #3f3f3f;
  z-index: 1000;
  cursor: pointer;
}

.dws_menu li > ul li a {
  padding: 10px;
  text-transform: none;
  border-bottom: 2px solid red;
  cursor: pointer;
}

.dws_menu li > ul li ul {
  position: absolute;
  right: -150px;
  top: 0;
}

.dws_menu li:hover > ul {
  display: block;
}