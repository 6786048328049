@import "fonts";
html,
body {
    height: 100%;
}
#root {
    height: 100%;
}
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    scroll-behavior: smooth;
    scroll-behavior: smooth;
    outline: 0;
    font-family: sans-serif;
    box-sizing: border-box;
}
.page {
    display: flex;
    flex-direction: column;
    height: 100%;
}
main {
    flex: 1 1 auto;
}
.clear {
    clear: both;
}
.center {
    width: 87%;
    margin: 0 auto;
}

.title {
    width: 700px;
    height: 45px;
    text-align: center;
    margin: 0 auto;
    margin-top: 138px;
    margin-bottom: 41px;
    font-family: poppins-semibold;
    font-size: 29px;
    position: relative;
    text-transform: uppercase;
    img {
        position: absolute;
        bottom: -12px;
        left: 50px;
        z-index: -3;
    }
}

.top {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #eeeef0;
    position: fixed;
    right: 100px;
    bottom: 70px;
    z-index: 20;
    cursor: pointer;
    opacity: 1;
}
.arrow {
    width: 20px;
    height: 20px;
    border-left: 2px solid #fe0000;
    border-top: 2px solid #fe0000;
    transform: rotate(45deg);
    margin: 19px 0px 0px 15px;
}
.top:hover {
    background-color: #fe0000;
}
.top:hover .arrow {
    border-left: 2px solid #eeeef0;
    border-top: 2px solid #eeeef0;
}
