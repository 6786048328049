@import "src/styles/mixins";

.cartPage {
    margin-top: 60px;
    .cartRow {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        .cartItems {
            flex: 0 0 80%;
            .cartItem {
                background-color: #ccc;
                padding: 15px 10px;
                margin-bottom: 15px;
                .cartItemRow {
                    display: flex;
                    @include flexRow(15, 4, flex-start);
                    .imageBlock {
                        width: 100%;
                        height: 150px;
                        text-align: center;
                        background-color: #fff;

                        img {
                            max-width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
        .cartInfo {
            flex: 0 0 calc(20% - 15px);
            .cartInfoContent {
                padding: 20px;
                padding-bottom: 80px;
                background-color: #fbf7f7;
                p {
                    margin-bottom: 35px;
                    &:last-of-type {
                        margin-bottom: 40px;
                        margin-top: 70px;
                    }
                }
                button {
                    display: block;
                    padding: 15px 20px;
                    margin-top: 15px;
                    width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                    cursor: pointer;

                    &:first-of-type {
                        margin-top: 40px;
                        border: none;
                        box-shadow: inset;
                        color: white;

                        background: rgb(150, 30, 46);
                    }
                    &:last-of-type {
                        border: none;
                        box-shadow: inset;
                        color: black;
                        background: #cfcfcf;
                    }
                }
            }
        }
        .counter {
            margin-top: auto;
            border: 1px solid #000;
            display: inline-flex;
            margin-top: 100px;

            button {
                padding: 4px;
                height: 30px;
                width: 25px;
                border: 0;
                cursor: pointer;
                &:first-of-type {
                    border-right: 1px solid #000;
                }
                &:last-of-type {
                    border-left: 1px solid #000;
                }
            }
            input {
                height: 30px;
                width: 50px;
                border: 0;
                padding: 8px;
                text-align: center;
            }
        }
    }
}
