.userProfileTabs {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .userProfileRow {
        display: flex;
        gap: 40px;
        width: 100%;
        .userProfileLeft {
            flex: 0 0 30%;
        }
        .userProfileTabList {
            list-style-type: none;
            li {
                button {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%;
                    height: 40px;
                    background-color: rgb(201 201 201);
                    border: 0;
                    margin-bottom: 15px;
                    border-radius: 6px;
                    position: relative;
                    text-align: left;
                    padding-left: 50px;
                    font-size: 16px;
                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 40px;
                        background-color: rgb(150, 30, 46);
                        border-radius: 6px;
                    }
                }
            }
        }
    }
    .userRight {
        flex: 1 1 auto;
    }
    .wrapperBlock {
        .formContent {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            border: 1px solid #ecd6ff;
            padding: 40px;
            background-color: #fbf7ff;
            border-radius: 8px;
            input {
                border-radius: 5px;
                padding: 20px;
                background: rgb(201 201 201);
                border: none;
                width: 90%;
            }
            .signIn {
                border-radius: 5px;
                padding: 20px;
                max-width: 50%;
                margin-left: 50%;
                transform: translateX(-50%);
                border: none;
                cursor: pointer;
                box-shadow: inset;
                color: white;
                background: rgb(152, 32, 49);
            }
            .singleItem {
                display: flex;
                align-items: center;
                label {
                    flex: 0 0 120px;
                }
                input {
                    height: 50px;
                    width: 100%;
                    border-radius: 35px;
                    padding: 20px;
                    background: rgb(201 201 201);
                    border: none;
                    &::placeholder {
                        text-align: center;
                        color: #000;
                    }
                }
                select {
                    height: 50px;
                    width: 100%;
                    border-radius: 35px;
                    background: rgb(201 201 201);
                    padding-left: 20px;
                    border: 0;
                    text-align: center;
                    &::placeholder {
                        text-align: center;
                        color: #000;
                    }
                }
                .threItems {
                    display: flex;
                    justify-content: space-between;
                    input {
                        flex: 0 0 120px;
                    }
                }
            }
        }
        .submitButton {
            background-color: rgb(150, 30, 46);
            border: 0;
            height: 30px;
            color: #fff;
            margin-top: 20px;
            margin-left: 120px;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 180px;
            cursor: pointer;
            border-radius: 6px;
        }
    }
    .emptyBlock {
        text-align: center;
        padding: 60px;
        + button {
            margin-left: auto !important;
            margin-right: auto;
            width: auto;
            min-width: 220px !important;
        }
    }
}

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
}


/* Card */

.card {
  width: 380px;
  height: 230px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background: rgb(201 201 201);
  border-radius: 10px;
  z-index: 1;
  overflow: hidden;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position: relative;
}

.visa_logo {
  float: right;
  padding: 10px;
}

.visa_logo img {
  width: 70px;
  height: 40px;
}

.visa_info {
  padding: 10px;
  margin: 30px 0;
}
.visa_info img {
  width: 60px;
  height: 45px;
}

.visa_info p {
  font-size: 22px;
  padding: 10px 0;
  letter-spacing: 2px;
  color: #ffffff;
}

.visa_crinfo {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  color: #ffffff;
}

.test{
    width: 45vw;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 10px;
   
}
.formContent1 {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    border: 1px solid #ecd6ff;
    padding: 40px;
    background-color: #fbf7ff;
    border-radius: 8px;
    align-items: center;
    height: 100vh;
    justify-content: space-around;

    
}
.order{
    width: 100%;
    height: 25%;
    background-color: rgba(160, 153, 153, 0.285);
    border-radius: 8px;
    display: flex;
   padding-top: 25px;
   
   justify-content: space-around;
}
.container_img{
    height: 84%;
    width: 25%;
    background-color: #ffffff;
    border-radius: 8px;
}
.subtotal{
    width: 300px;
    height: 150px;
    display: flex;
    flex-direction: column;
    background-color: rgba(238, 213, 213, 0.248);
    
    justify-content: space-around;
    border-radius:8px ;
}
.subtotalCont{
    width: 86%;
    display: flex;
    align-items: end;
    gap: 120px
}
.logo{
    width: 70px;
    height: 70px;
    display: block;
    > img{
        width: 100%;
    }
}