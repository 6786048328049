.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  flex-direction: column;
  border-radius: 20px;
  background: rgb(250, 247, 254);
  border-color: rgb(201 201 201);
  padding: 20px 30px;
}
.input {
  border-radius: 35px;
  padding: 20px;
  background: rgb(201 201 201);
  border: none;
  width: 90%;
}

.buttonsBlock {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
}

.signIn {
  border-radius: 35px;
  padding: 20px;
  border: none;
  cursor: pointer;
  box-shadow: inset;
  color: white;
  background: rgb(47, 47, 47);
}

.forgotPass {
  background: none;
  border: none;
  color: rgb(181,164,201);
  font-size: 20px;
  cursor: pointer;
}

.errorText {
  color: rgb(152, 32, 49);
  text-align: center;
  font-size: 20px;
}

.register {
  border-radius: 35px;
  padding: 20px;
  border: none;
  cursor: pointer;
  box-shadow: inset;
  color: white;
  background: rgb(150, 30, 46);
  width: 70%;
}