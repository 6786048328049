.topSection {
    .m_done {
        display: none;
    }
    .boxShadow {
        box-shadow: 0 0 11px rgba(0, 0, 0, 0.1);
        .boxShow {
            .topContainer {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                padding-top: 29px;
                margin-bottom: 35px;
                .logo {
                    a {
                        img {
                            width: 130px;
                            height: 30px;
                        }
                    }
                }
                .search {
                    width: 62%;
                    height: 46px;
                    position: relative;
                    input {
                        width: 100%;
                        height: 46px;
                        border: none;
                        border-radius: 23px;
                        background-color: #f3f3f3;
                        font-family: poppins-light;
                        font-size: 17px;
                        padding-left: 23px;
                        color: #1d1d1d;
                        outline: 0;
                    }
                    .search_img_b {
                        position: absolute;
                        top: 10px;
                        right: 20px;
                        bottom: 10px;
                        cursor: pointer;
                    }
                    .search_img,
                    .close_search,
                    .more_img {
                        display: none;
                    }
                }
                .centerInfo {
                    display: flex;
                    .login {
                        display: flex;
                        cursor: pointer;
                        margin-left: 25px;
                        align-items: center;
                    }
                    .login p {
                        font-family: poppins-regular;
                        font-size: 16px;
                        margin-left: 16px;
                    }
                    .am_text {
                        font-family: Mardoto-Regulard !important;
                    }
                    .ru_text {
                        font-family: Roboto-Regular !important;
                    }
                    .d_none {
                        display: none !important;
                    }
                }
                .admin_link {
                    display: flex;
                    .admin_link__img{
                        width: 35px;
                    }
                }
                .right_container {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: flex-end;
                    font-family: poppins-regular;
                    position: relative;
                    width: 20px;
                    .basket {
                        position: relative;
                        a {
                            .number {
                                width: 15px;
                                height: 15px;
                                border-radius: 50%;
                                background-color: #d71117;
                                position: absolute;
                                bottom: -3px;
                                right: -7px;
                                .b_items_count {
                                    font-size: 10px;
                                    color: #ffff;
                                    text-align: center;
                                    position: absolute;
                                    right: 6px;
                                }
                            }
                        }
                    }
                    .menu_img_open {
                        display: none;
                    }
                }
            }
        }
    }
}
.search_img,
.close_search {
    display: none;
}
