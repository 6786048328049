@import "src/styles/mixins";

.adminPage {
  margin-top: 60px;
  width: 100%;
}

.adminRow {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
}

.adminHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  margin: 0 0 40px;

  div {
    margin-left: 215px;
    font-family: poppins-regular;
    font-size: 20px;
    @media screen and (max-width: 768px) {
      margin: 0 auto;
    }
  }
}

.adminItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  margin: 0 0 80px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .adminItem__title {
    text-transform: uppercase;
    font-weight: bold;
    margin:0 0 4px;
  }

  .adminItem__user {
    flex: 0 0 200px;
    font-family: poppins-regular;
    font-size: 16px;

    span {
      text-transform: uppercase;
      font-weight: bold;
    }

    @media screen and (max-width: 768px) {
      flex: 1 1 100%;
    }
  }

  .adminItem__order {
    flex: 1 1 auto;
    display: flex;
    gap: 24px 15px;
    align-items: stretch;
    flex-wrap: wrap;

    > div {
      flex: 0 0 calc(33.3% - 15px * 2 / 3);
      height: auto;
      @media screen and (max-width: 992px) {
        flex: 0 0 calc(50% - 15px / 2);
      }
      @media screen and (max-width: 425px) {
        flex: 0 0 100%;
      }

      > span {
        background-color: #ccc;
        padding: 8px 8px 8px 20px;
        font-size: 16px;
      }

      > div {
        div {
          background: rgb(150, 30, 46);
        }
      }
    }
  }
}