.viewAll {
    display: flex;
    justify-content: flex-end;
    p {
        cursor: pointer;
        margin-bottom: 1px;
        color: #000000;
        font-size: 16px;
        font-family: poppins-semibold;
    }
}
.naviagitonItem {
    z-index: 3;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 75px;
    height: 75px;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    &.next_item {
        right: 1px;
    }
    &.prev_item {
        left: 1px;
    }
}
